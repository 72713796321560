import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
